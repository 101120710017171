.collabs-carousel .slick-slide {
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0.5;
  transform: scale(0.8);
  z-index: 1;
}

.collabs-carousel .slick-center {
  transform: scale(1);
  opacity: 1;
  z-index: 10;
}

.collabs-carousel .slick-slide video {
  border: 3px solid transparent;
}

.collabs-carousel .slick-center video {
  border: 3px solid neon-green;
}

.carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-item .text-content {
  text-align: center;
  padding-top: 10px;
}

.slick-arrow {
  background-color: neon-green;
  color: black;
  border: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
}

.slick-arrow:hover {
  background-color: darkgreen;
}

.slick-dots {
  bottom: -25px;
}

.slick-dots li.slick-active button {
  background-color: neon-green;
}

.slick-dots li button {
  background-color: gray;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  outline: none;
}

.slick-dots li button:hover {
  background-color: darkgreen;
}

.carousel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-wrapper {
  width: 100%;
  height: 300px;
  max-width: 600px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

@media (min-width: 1024px) {
  .carousel-item {
    flex-direction: row;
  }

  .text-content {
    margin-left: 20px;
  }
}